<template>
  <div>
    <content-template
      :menuName="item.title"
      :backButton="true"
      @backToPage="moveToOther('Event Publication')"
    >
      <template #article>
        <div class="s-mb-20 s-flex" v-if="item.status == 'Menunggu verifikasi'">
          <div class="s-ml-auto">
            <Button
              color="error"
              @click.prevent="onVerifyAction('reject', item.id)"
              >{{ $t("general.reject") }}</Button
            >
            <Button
              class="s-ml-20"
              @click.prevent="onVerifyAction('accept', item.id)"
              >{{ $t("general.accept") }}</Button
            >
          </div>
        </div>
        <img
          :src="item.image"
          :alt="item.title"
          class="i-detail-image i-shadow"
        />
        <p style="margin: 20px 0px 30px 0px">
          <span style="float: left">
            <b>{{ convertDate(item.date) }} | {{convertTime(item.time)}}</b>
          </span>
          <span style="float: right">
            <b>{{ item.creator_name }}</b>
          </span>
        </p>
        <br />
        <hr />
        <div class="i-square s-text-white">
          <h5 class="s-mtb-5 s-text-white">{{ $t("general.description") }}</h5>
          <span class="s-text-white" style="text-align: justify">{{
            item.description
          }}</span>
        </div>

        <div class="i-square">
          <h5 class="s-mtb-5 s-text-white">
            {{ $t("eventPublication.eventLink") }}
          </h5>
          <span class="s-text-white">{{ item.event_link }}</span>
        </div>

        <div class="i-square">
          <h5 class="s-mtb-5 s-text-white">{{ $t("general.location") }}</h5>
          <div class="s-text-white" v-html="item.location"></div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";
import { convertDate, convertTime} from "@/common/timeformat";
export default {
  components: { ContentTemplate },
  mixins: [form],
  mounted() {
    this.initData();
  },

  computed: {
    ...mapGetters({
      item: "event/getEvent",
    }),
  },

  methods: {
    ...mapActions({
      onVerify: "event/onVerify",
      setEvent: "event/setEventSingleData",
    }),

    async onVerifyAction(action, id) {
      const data = {
        id,
        action,
      };
      await this.onVerify(data);
      if (this.$store.getters["event/getStatus"] == 1) {
        this.$router.push(this.$translate({ name: "Event Publication" }));
      }
    },

    async initData() {
      this.setLoadingPage(true)
      await this.setEvent(this.$route.params.id);
       this.setLoadingPage(false)
    },
    convertDate(value) {
      return convertDate(value);
    },
    convertTime(value) {
      return convertTime(value);
    },
  },
};
</script>

