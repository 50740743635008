import { render, staticRenderFns } from "./eventPublicationDetail.vue?vue&type=template&id=d3711336&"
import script from "./eventPublicationDetail.vue?vue&type=script&lang=js&"
export * from "./eventPublicationDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports